import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../service/api.ts';
import { parseJSON } from '../util';

import { useGlobalStoreUser } from '../context/global';

async function getUserPoints() {
  const response = await api.getUserPoints();
  console.log('POINTS', response?.data);
  return response?.data;
}

const UserPoints = () => {
  const [globalStoreUser, globalDispatchUser] = useGlobalStoreUser();
  const { t } = useTranslation('home');
  const [loyalty, setLoyalty] = useState({ points: -1 });

  useEffect(async () => {
    const res = await getUserPoints();
    if (res?.status !== 0) return;

    const data = parseJSON(res?.dane);
    if (data === false || !Array.isArray(data) || data.length === 0) return;

    const _loyalty = {
      points: parseFloat(data[0]?.loyalty_points || 0)
    };

    setLoyalty(_loyalty);
    globalDispatchUser.setLoyalty(_loyalty);
  }, [globalStoreUser]);

  return (
    <>
      {loyalty.points >= 0 && (
        <div className="hidden lg:block">
          <span className="text-gray-600">{t('Punkty lojalnościowe')}:</span>
          &nbsp;
          <span className="text-green-600">
            <span data-cy="user-point">{loyalty.points || 0}</span>
          </span>
        </div>
      )}
    </>
  );
};

export default UserPoints;
